<template>
  <div class="settings-container">
    <v-row>
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="changeRoute('Settings')">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          depressed
          :ripple="false"
          style="width: fit-content;"
          @click="changeRoute('SettingsMemberCondition')">
          <v-icon class="mr-1">
            mdi-cog-outline
          </v-icon>
          <span>
            จัดการเงื่อนไขระดับสมาชิก
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div class="content-wrapper rounded-lg">
          <v-row>
            <v-col cols="12">
              <span
                class="primary--text"
                style="font-size: 24px;">
                ระดับสมาชิกลูกค้า
              </span>
            </v-col>
            <v-col cols="12">
              <div class="rank-container">
                <div
                  v-for="rank of ranks"
                  :key="rank.name"
                  class="rank-item rounded-lg">
                  <div
                    class="rounded-pill px-4 py-2 d-flex flex-row align-center justify-center"
                    :class="`rank-${rank.name}`">
                    <v-icon color="white">
                      mdi-crown-outline
                    </v-icon>
                    <span class="font-weight-bold text-capitalize white--text">
                      {{ rank.name }}
                    </span>
                  </div>
                  <span class="font-weight-bold secondary--text">
                    เงื่อนไข :
                  </span>
                  <div class="secondary--text">
                    <span v-if="rank.condition === 'point'">
                      มีคะแนนสะสม
                      {{ rank.maxPoint | showNumberFormat() }}
                      คะแนน
                    </span>
                    <span v-else>
                      สมัครสมาชิกครั้งแรก
                    </span>
                  </div>
                  <v-btn
                    color="primary"
                    depressed
                    :ripple="false"
                    style="width: fit-content;"
                    @click="viewRankDetail(rank.name)">
                    รายละเอียด
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="content-wrapper rounded-lg">
          <v-row>
            <v-col cols="12">
              <span
                class="primary--text"
                style="font-size: 24px;">
                สิทธิพิเศษ
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4">
              <SearchBox
                v-model="query.search"
                :disabled="loading"
                @search="getItems(true)" />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="auto">
              <v-btn
                color="primary"
                outlined
                depressed
                block
                :ripple="false"
                style="width: fit-content; background-color: white;"
                @click="createItem()">
                <span>
                  สร้างสิทธิพิเศษ
                </span>
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                hide-default-footer
                class="rounded-lg"
                style="border: 1px solid #E0E0E0">
                <template #[`item.createdAt`]="{ item }">
                  {{ item.createdAt | dateFullFormat() }}
                </template>
                <template #[`item.status`]="{ item }">
                  <span :class="checkStatus(item).color">
                    {{ checkStatus(item).text }}
                  </span>
                </template>
                <template #[`item.forRank`]="{ item }">
                  <span class="text-capitalize">
                    {{ item.forRank || 'ใช้ได้ทุกระดับ' }}
                  </span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    color="primary"
                    icon
                    :disabled="loading"
                    @click="editItem(item)">
                    <v-icon>
                      mdi-square-edit-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    color="error"
                    icon
                    :disabled="loading"
                    @click="confirmDialogToggle(item)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-pagination
                  v-model="query.page"
                  :length="totalPages"
                  :total-visible="7"
                  circle
                  color="primary" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MemberRanksProvider from '@/resources/member-ranks.provider'
import PrivilegesProvider from '@/resources/privileges.provider'
import SearchBox from '@/components/SearchBox.vue'

const MemberRanksService = new MemberRanksProvider()
const PrivilegesService = new PrivilegesProvider()

export default {
  components: { SearchBox },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ชื่อสิทธิพิเศษ',
        value: 'name',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'table-header',
        align: 'right',
        sortable: false
      },
      {
        text: 'ระดับลูกค้า',
        value: 'forRank',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    ranks: [],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: ''
    },
    totalPages: 1
  }),
  async mounted () {
    await this.getRanks()
    this.getItems(true)
  },
  methods: {
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    checkStatus (item) {
      const today = this.$dayjs()

      if (this.$dayjs(item.endDate).isBefore(today)) {
        return {
          text: 'หมดอายุ',
          color: 'accent--text'
        }
      }

      if (this.$dayjs(today).isBefore(item.startDate)) {
        return {
          text: 'ยังไม่ถึงกำหนด',
          color: 'gold--text'
        }
      }

      return {
        text: 'ช่วงโปรโมชัน',
        color: 'success--text'
      }
    },
    async getRanks () {
      try {
        this.loading = true

        const { data } = await MemberRanksService.getItems({
          page: 1,
          limit: 9999,
          sortOrder: 'asc'
        })

        this.ranks = data.results
      } catch (error) {
        console.error('getRanks', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PrivilegesService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await PrivilegesService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.$router.push({ name: 'SettingsMemberPrivilegeCreate' })
    },
    editItem (item) {
      this.$router.push({
        name: 'SettingsMemberPrivilegeEdit',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.name}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    },
    viewRankDetail (rank) {
      this.$router.push({
        name: 'SettingsMemberRank',
        params: { rank }
      })
    }
  }
}
</script>

<style scoped>
.settings-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.content-wrapper {
  width: 100%;
  border: 1px solid #E0E0E0;
  background-color: white;
  padding: 1rem;
}
.rank-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem 0.5rem;
}
.rank-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  width: 25%;
  gap: 1rem;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}
</style>

<style>
.rank-bronze {
  background: linear-gradient(270deg, #895E1A 0%, #B08D57 100%);
}
.rank-silver {
  background: linear-gradient(270deg, #A2AFBB 0%, #BDCFDF 100%);
}
.rank-gold {
  background: linear-gradient(270deg, #DBA514 0%, #FCC201 100%);
}
.rank-platinum {
  background: linear-gradient(270deg, #396B7F 0%, #7CABBF 100%);
}
</style>
